.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
 /deep/ .yd-dialog-black-mask .yd-dialog-white-mask {
  z-index: 9999999 !important;
}
.clickisTrue {
  width: 54%;
  position: absolute;
  z-index: 999;
  margin-left: 23%;
  bottom: 13%;
}
.spotFabulous {
  position: absolute;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: auto;
}
.afterspotFabulousText {
  /* position: absolute;
    width: 100%;
    text-align: center;
    z-index: 999999;
    left: 0; */
  /* line-height: 5; */
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1000;
  display: flex;
  left: 0;
  height: 100%;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  font-size: 16px;
  word-break: break-all;
  line-height: 1.3;
  bottom: 0;
}
.pop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
}
.pop .pop-alert {
  width: 80%;
  height: 30%;
  position: absolute;
  left: 10%;
  margin-top: 47%;
  border-radius: 10px;
  background: #eee;
}
.pop .pop-alert .yd-input {
  position: absolute;
  border: 1px solid #000;
  width: 60%;
  height: 40px;
  right: 5%;
  margin-top: 22%;
  font-size: 20px;
  padding-left: 10px;
}
.pop .pop-alert .isTrue {
  position: absolute;
  width: 50%;
  height: 50px;
  border: 0;
  left: 0;
  font-size: 22px;
  color: #fff;
  background-color: #1989fa;
  border-radius: 0px 0px 0px 10px;
  bottom: 0;
}
.pop .pop-alert .isFasle {
  position: absolute;
  width: 50%;
  height: 50px;
  right: 0;
  font-size: 22px;
  color: #fff;
  border: 0;
  background-color: #f00;
  bottom: 0;
  border-radius: 0px 0px 10px 0px;
}
.pop .pop-alert span {
  float: left;
  font-size: 20px;
  margin-left: 30px;
  margin-top: 25%;
  color: #333;
}
.clickSucss {
  width: 76%;
  position: absolute;
  height: 15%;
  margin-left: 12%;
  background-image: url("../../assets/WechatIMG14.png");
  background-size: 100% 100%;
  bottom: 10%;
}
.clickSucss p {
  width: 80%;
  margin-left: 10%;
  position: absolute;
  bottom: 11%;
  font-size: 0.3rem;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  color: #7b2900;
}
.beforeZlBackground {
  width: 100%;
  position: absolute;
}
.beforeZlXs {
  width: 100%;
  position: absolute;
}
.text1 {
  width: 100%;
  left: 0;
  margin-top: 5%;
}
.bottom1 {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.page {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.page .lastBottom {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.2rem;
  color: #000;
  font-weight: 400;
  background-color: #fff;
  letter-spacing: 2px;
}
.page .content {
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  margin-top: 0.7rem;
}
.page .bgimg {
  width: 100%;
  top: 0;
  position: absolute;
}
.page .home-icon,
.page .collect-icon {
  width: 0.56rem;
  height: 0.56rem;
  border-radius: 0.56rem;
  position: absolute;
  z-index: 110;
}
.page .home-icon img,
.page .collect-icon img {
  width: 0.56rem;
  height: 0.56rem;
}
.page .home-icon {
  left: 0.3rem;
  top: 0.4rem;
}
.page .collect-icon {
  right: 0.3rem;
  top: 0.4rem;
}
